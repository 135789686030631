<template>
  <div class="main">
    <!-- <div > -->
    <div class="un-container">
      <v-card
        class="card-shadow"
        :class="config.gauge ? 'cardStyleForGauge' : 'card'"
        :style="cardType == 'card-row' ? 'width: 100%' : ''"
        elevation="5"
        style="border-radius: 15px !important"
      >
        <v-container grid-list-md text-xs-center fluid style="padding: unset">
          <v-layout dense>
            <v-flex xs12 md12 lg12 sm12>
              <!-- <v-container> -->
              <v-layout :row="config.gauge" :wrap="config.gauge" dense>
                <v-flex xs12 md12 sm6 lg6>
                  <span v-if="dataProvider.length == 0">{{
                    config.messageForNoData || 'No data'
                  }}</span>
                  <v-list-item
                    :style="
                      config.gauge && windowWidth < 768
                        ? 'text-align-last:center'
                        : ''
                    "
                    two-line
                    :dark="false"
                  >
                    <v-list-item-content
                      :style="config.gauge ? 'margin-top:1.3rem' : ''"
                      style="height: 60px"
                    >
                      <v-list-item-subtitle
                        style="
                          color: white;
                          font-size: medium;
                          font-weight: bold;
                          padding-bottom: 6px;
                        "
                        :style="config.gauge ? 'text-align:center' : ''"
                      >
                        {{ config.title }}
                      </v-list-item-subtitle>
                      <v-list-item-title
                        v-if="mainNumber || mainNumber === 0"
                        style="
                          color: white;
                          font-size: 1.8rem !important;
                          font-weight: bold;
                          overflow: visible;
                        "
                        :style="config.gauge ? 'text-align:center' : ''"
                      >
                        {{ config.prefix }}
                        {{
                          (mainNumber &&
                            mainNumber.toLocaleString('en-IN', {
                              maximumFractionDigits: 2
                            })) ||
                          0
                        }}
                        {{ config.sufix }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <div v-if="!config.gauge">
                    <v-progress-linear
                      style="width: 85%; border-radius: 10px; margin-left: 18px"
                      class="progress"
                      color="#ffffffcc"
                      height="15"
                      :value="Math.ceil(progress_bar_value)"
                    >
                    </v-progress-linear>
                    <v-list-item-subtitle
                      style="
                        color: white;
                        font-size: medium;
                        font-weight: bold;
                        text-align: start;
                        padding-left: 20px;
                      "
                    >
                      {{ config.progress_bar_title }} :
                      {{ Math.ceil(progress_bar_value) }}%
                    </v-list-item-subtitle>
                  </div>
                </v-flex>
                <v-flex v-if="config.gauge" xs12 md12 sm6 lg6>
                  <vue-gauge
                    :config="config"
                    :options="gaugeOptions"
                    :progress_bar_value="progress_bar_value"
                  />
                </v-flex>
                <v-flex v-if="!config.gauge" xs4 md4 lg4 sm4>
                  <!-- align="center" justify="center" -->
                  <div class="extra-circle-2">
                    <div class="extra-circle"></div>
                  </div>
                  <!-- <v-icon style="width:100%;height: 100%; color: white;" x-large>{{ icon }}</v-icon> -->
                </v-flex>
              </v-layout>
              <!--  </v-container> -->
            </v-flex>
            <!-- </template> -->
          </v-layout>
        </v-container>
      </v-card>
    </div>
  </div>
  <!-- </div> -->
</template>


<script>
import VueGauge from 'vue-gauge'
import { makeDataProvider } from '../common.js'
export default {
  props: ['configuration', 'dataSource'],
  components: { VueGauge },
  data() {
    return {
      cardType: 'card-row',
      mainNumber: '',
      progress_bar_value: 0,
      windowWidth: window.innerWidth,
      dataProvider: [],
      gaugeOptions: {
        hasNeedle: true,
        outerNeedle: true,
        needleColor: 'white',
        needleValue: 20,
        needleUpdateSpeed: 1000,
        arcColors: [
          'hsl(5, 99%, 50%)',
          'hsl(60, 100%, 47%)',
          'hsl(90, 100%, 40%)'
        ],
        arcDelimiters: [40, 60],
        rangeLabel: ['0', '100'],
        rangeLabelFontSize: 10
      }
    }
  },
  // mounted: function () {
  //           console.log("superman", this.progress_bar_value)
  //           let element = document.querySelector(`#${this.gaugeId}`)
  //           let gaugeOptions = {
  //               hasNeedle: true,
  //               outerNeedle: true,
  //               needleColor: 'white',
  //               needleValue: 20,
  //               needleUpdateSpeed: 1000,
  //               arcColors: ['hsl(5, 99%, 50%)', 'hsl(60, 100%, 47%)', 'hsl(90, 100%, 40%)'],
  //               arcDelimiters: [40, 60],
  //               rangeLabel: ['0', '100'],
  //               centralLabel: `${this.progress_bar_value.toString()}%`,
  //               rangeLabelFontSize: 10
  //           }
  //           console.log("gaugeOptions", gaugeOptions)
  //           // Drawing and updating the chart
  //           console.log("vuetify", this.$vuetify.breakpoint)
  //           const breakpoint = {
  //               xs: 200,
  //               sm: 200,
  //               md: 150,
  //               lg: 250,
  //               xl: 250
  //           }
  //           let size, x, y;
  //           Object.keys(breakpoint).some(bk => {
  //               if (this.$vuetify.breakpoint[bk]) {
  //                   size = breakpoint[bk];
  //               }
  //           })
  //           if (this.$vuetify.breakpoint.name == 'lg') {
  //               x = 122;
  //               y = 135;
  //           }
  //           if (this.$vuetify.breakpoint.name == 'xs') {
  //               x = 100;
  //               y = 113;
  //           }
  //           if (this.$vuetify.breakpoint.name == 'sm') {
  //               x = 100;
  //               y = 113;
  //           }
  //           if (this.$vuetify.breakpoint.name == 'md') {
  //               x = 80;
  //               y = 92;
  //           }
  //           VueGauge.gaugeChart(element, size, gaugeOptions).updateNeedle(this.progress_bar_value)
  //           const SVG_NS = "http://www.w3.org/2000/svg"
  //           let textNode = document.createElementNS(SVG_NS, 'text')
  //           textNode.setAttributeNS(null, 'x', x)
  //           textNode.setAttributeNS(null, 'y', y)
  //           textNode.setAttributeNS(null, 'font-size', '16px')
  //           textNode.setAttributeNS(null, 'font-family', "Roboto,Helvetica Neue,sans-serif")
  //           textNode.setAttributeNS(null, 'fill', "white")
  //           textNode.setAttributeNS(null, 'text-anchor', "middle")
  //           textNode.innerHTML = this.config.progress_bar_title
  //           // console.log("chart rendered", textNode)
  //           // console.log("refsrefs",this.$refs)
  //           let newNode = this.$refs.gauge.getElementsByTagName("svg")[0];
  //           // console.log("refsre", newNode)
  //           newNode.append(textNode)
  //           this.$nextTick(() => {
  //               this.$refs.gauge.children[0].children[8].style.fill = 'white'
  //               this.$refs.gauge.children[0].children[9].style.fill = 'white'
  //               this.$refs.gauge.children[0].children[10].style.fill = 'white'
  //               this.$refs.gauge.children[0].children[10].style.fontSize = '15px'
  //               // let textNode = this.$refs.gauge.children[0].children[10].cloneNode(true);
  //               // console.log('textNode :>> ', textNode);
  //               // textNode.innerHTML = this.config.progress_bar_title
  //               // this.$refs.gauge.children[0].children[10].append(textNode)
  //           })
  //       },
  created() {
    console.log('VueGauge :>> ', VueGauge)
    this.config = JSON.parse(JSON.stringify(this.configuration || {}))
    if (
      this.dataSource &&
      this.dataSource.headers &&
      this.dataSource.headers.length
    ) {
      if (this.dataSource.rows.length) {
        this.dataProvider = this.dataSource.rows
      } else {
        this.dataProvider = makeDataProvider(this.dataSource)
      }
    }
    let dataProvider = this.dataProvider[0]
    this.mainNumber = dataProvider[this.config.mainNumberField]
    this.progress_bar_value = dataProvider[this.config.progress_bar_value]
    this.gaugeOptions.centralLabel = `${this.progress_bar_value.toString()}%`
  },
  methods: {}
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
}
.un-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.card-shadow:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.6s;
}
.extra-circle {
  position: absolute;
  border-top-left-radius: 100%;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 5%;
  /* border-bottom-left-radius: 100%; */
  /* padding: 12%; */
  height: 97.5%;
  width: 60%;
  right: 0;
  /* top: 6.7%; */
  /* border: 1px solid red; */
  background-color: rgba(255, 255, 255, 0.6);
}

.extra-circle-2xx {
  border-top-left-radius: 100%;
  /* border-bottom-left-radius: 100%; */
  padding: 10%;
  height: 140%;
  width: 100%;
  padding-left: 10%;
  margin-right: -60px;
  /* margin-bottom: -50%; */
  /* margin-top: 10px; */
  /* margin-left: 65px; */
  background-color: rgba(255, 255, 255, 0.2);
}
.card {
  /* background-image: linear-gradient(to bottom right, #2C3E50, #4CA1AF);  */
  /* max-width: 1100px !important; */
  min-width: 40% !important;
  background-color: #08aeea;
  background-image: linear-gradient(45deg, #08aeea 31%, #2af598 100%);
}
.cardStyleForGauge {
  max-width: 1100px !important;
  min-width: 35% !important;
  height: 200px;
  background-color: #08aeea;
  background-image: linear-gradient(45deg, #000046 31%, #1cb5e0 100%);
}
</style>